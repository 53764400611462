import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_US from "./languages/v1/en_US.json";
import zh_CN from "./languages/v1/zh_CN.json";
import en_US_v2 from "./languages/v2/en_US.json";
import zh_CN_v2 from "./languages/v2/zh_CN.json";

// V1 海外校对版
import en_US_v1_calibrate from "./languages/v1-calibrate/en_US.json";
import zh_CN_v1_calibrate from "./languages/v1-calibrate/zh_CN.json";

// 泰语
import th_TH from "./languages/v1/th_TH.json";
// 越南
import id_ID from "./languages/v1/id_ID.json";
// 印尼
import vi_VN from "./languages/v1/iv_VN.json";

import { languages } from "zlink-front";
import { deepObjectMerge } from "@/utils/index"

const resources = {
  'en-US': {
    translation: deepObjectMerge(languages.enUS, en_US, en_US_v2, en_US_v1_calibrate)
  },
  'zh-CN': {
    translation: deepObjectMerge(languages.zhCN, zh_CN, zh_CN_v2, zh_CN_v1_calibrate)
  },
  'th-TH': {
    translation: deepObjectMerge(languages?.thTH, th_TH)
  },
  'id-ID': {
    translation: deepObjectMerge(languages?.idID, id_ID)
  },
  'vi-VN': {
    translation: deepObjectMerge(languages?.viVN, vi_VN)
  },
};
console.log('------zlink_en-US---', deepObjectMerge(en_US, en_US_v2, en_US_v1_calibrate))


i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'zh-CN',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;