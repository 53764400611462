import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { commonRoutes } from "./modules";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import enGB from 'antd/lib/locale/en_GB';
import zhTW from 'antd/lib/locale/zh_TW';
import idID from 'antd/lib/locale/id_ID';
import viVN from 'antd/lib/locale/vi_VN';
import thTH from 'antd/lib/locale/th_TH';
const langOptions ={
  "en-US": enGB,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
  "id-ID": idID,
  "vi-VN": viVN,
  "th-TH": thTH
}

const ZRouter = (props) => {
  const { i18n } = useTranslation();
  return (
    <>
      <ConfigProvider locale={langOptions[i18n.language]}>
        <Router basename={window.__POWERED_BY_QIANKUN__ ? '/zkbio_vis' : '/'}>
          <Routes>
            {
              commonRoutes.map( item => {
                return <Route key={item.path} path={item.path} element={item.element} />
              })
            }
            <Route path="/" element={<Navigate to={'/vis/VisitorInvitationList'}/>} />
          </Routes> 
        </Router>
      </ConfigProvider>
    </>
  )
}

const mapStateToProps = ({ account }) => ({
  token: account.token
})

export default connect(
  mapStateToProps
)(ZRouter)